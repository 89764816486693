import request from '../utils/request'
import { _GLOBAL } from '../api/server'

export function getSettingParam () {
  // console.log('on api getSettingParam')
  var data = null
  var uri = _GLOBAL.URLBACKEND + '/settingparams?Deskripsi=isAbsenOnline'
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}
