<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >

    <v-row>      
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >
        <base-material-tabs
          v-model="tab"

          color="warning"
          icons-and-text
        >
          <v-tab
            v-for="(tab, i) in tabs"
            :key="i"
          >
            {{ tab.text }}
            <v-icon v-text="tab.icon" />
          </v-tab>

          <v-tabs-items
            v-model="tab"
            class="pt-12 transparent"
          >

            <v-tab-item>
              <!--
                <v-row>
                  <v-col cols="12">
                    <span class="font-italic">{{ address }}</span>
                  </v-col>
                </v-row>
              -->
              <v-container v-if="canAbsenOnline == 1">
                <v-row dense>
                  <v-col cols="12" md="6" sm="6">
                  <v-alert
                      border="top"
                      colored-border
                      color="#1F7087"
                      elevation="2"
                    >
                      <p class="display-1">Absen <b>Check-IN WFO/WFH</b> aktif pada jam <b>05:00 - 09:00</b></p>
                      <!--<p class="display-1">Absen Bln Ramadhan <b>Check-IN WFO/WFH</b> aktif pada jam: <br><b>06:00 - 09:00</b></p> <!--ramadhan-->
                    </v-alert>
                    <v-card
                          class="mx-auto"
                          color="#1F7087"
                          dark
                        >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="headline mb-4">Check-IN</div>
                            <v-list-item-title class="headline mb-1 yellow--text text-right"><span v-if="extra.worksts">{{extra.worksts}} - </span> {{absenIn | formatDate}}</v-list-item-title>
                            <v-list-item-subtitle class="text-right"><span v-if="extra.worksts_ST">{{extra.worksts_ST}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle class="text-right">{{locIn}}</v-list-item-subtitle>
                            <!--<v-list-item-subtitle class="text-right">Info Keterlambatan (TL): {{infoTL}}</v-list-item-subtitle>-->
                          </v-list-item-content>
                        </v-list-item>

                      <v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.7"
                          :value="boolBtnIN"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn v-if="boolBtnIN" color="primary" :disabled="isLoading" dark v-on="on" @click="dialogIN = true"> Check-IN disini!</v-btn>
                              <v-btn v-else disabled color="primary" dark v-on="on">Check-IN Tidak Aktif!</v-btn>
                            </template>
                            <span v-if="boolBtnIN">Silakan Check-IN KLIK Disini.</span>
                            <span v-else>Tombol Check-IN tidak aktif.</span>
                          </v-tooltip>
                        </v-overlay>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="12"  md="6" sm="6">
                    <v-alert
                      border="top"
                      colored-border
                      color="#952175"
                      elevation="2"
                    >
                      <p class="display-1">Absen <b>Check-OUT WFO/WFH</b> aktif pada jam <b>15:30 - 23:59</b></p>
                      <!--<p class="display-1">Absen Bln Ramadhan <b>Check-OUT WFO/WFH</b> aktif pada jam: <br><b>14:00 - 23:59</b></p><!--ramadhan-->
                    </v-alert>

                    <v-card
                          class="mx-auto"
                          color="#952175"
                          dark
                        >
                        <v-list-item three-line>
                          <v-list-item-content>
                            <div class="headline mb-4">Check-OUT</div>
                            <v-list-item-title class="headline mb-1 yellow--text text-right">{{absenOut | formatDate}}</v-list-item-title>
                            <v-list-item-subtitle class="text-right"><span v-if="extra.worksts_ST">{{extra.worksts_ST}}</span></v-list-item-subtitle>
                            <v-list-item-subtitle class="text-right">{{locOut}}</v-list-item-subtitle>
                            <!--<v-list-item-subtitle class="text-right">Info Pulang Sebelum Waktunya (PSW): {{infoPSW}}</v-list-item-subtitle>-->
                          </v-list-item-content>
                        </v-list-item>

                      <v-card-actions>
                        <v-overlay
                          absolute
                          opacity="0.7"
                          :value="boolBtnOUT"
                        >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            
                            <v-btn v-if="diffMinute<570" disabled color="primary" dark v-on="on"><span class="yellow--text">Check-OUT Belum Aktif, <br>Waktu Kerja belum mencapai 9 Jam dan 30 Menit!</span></v-btn>
                            <!--<v-btn v-if="diffMinute<480" disabled color="primary" dark v-on="on"><span class="yellow--text">Check-OUT Belum Aktif, <br>Pada bulan Ramadhan waktu Kerja Anda belum mencapai 8 Jam!</span></v-btn><!--ramadhan-->
                            <v-btn v-else-if="boolBtnOUT" color="primary" :disabled="isLoading" dark v-on="on" @click="dialogOUT = true"> Check-OUT disini!</v-btn>
                            <v-btn v-else disabled color="primary" dark v-on="on">Check-OUT Tidak Aktif!</v-btn>
                          </template>
                          <span v-if="boolBtnOUT">Silakan Check-OUT KLIK Disini.</span>
                          <span v-else>Tombol Check-OUT tidak aktif.</span>
                        </v-tooltip>
                        </v-overlay>
                      </v-card-actions>
                    </v-card>

                  </v-col>
                </v-row>

              </v-container>
              <v-container v-else>
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                >
                  Absen Online sudah tidak diaktifkan dan kembali ke proses Absen NORMAL (Fingerprint) yang berada di Kantor BPDPKS.
                </v-alert>
              </v-container>
              <v-container>
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                >
                <p class="display-1" v-html="holidayInfo"></p>
                </v-alert>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <base-material-card
                  icon="mdi-clipboard-text"
                  title="List Data Absen"
                  class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                      <v-dialog
                          ref="SelMonth"
                          v-model="menuSelMonth"
                          :return-value.sync="selectedMonth"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="selectedMonth"
                            label="Pilih Bulan/Tahun"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedMonth" type="month" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuSelMonth = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.SelMonth.save(selectedMonth)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="feedingTable()">Show Data</v-btn>
                    </v-col>
                  </v-row>

                  <v-data-table
                    :headers="headers"
                    :items="listAbsens"
                    :items-per-page="15"
                    class="elevation-1"
                    @selected="feedingTable()"
                  >
                  <template v-slot:item.ket="{ item }">
                    <div>Check-IN: </div>
                    <div>Check-OUT: </div>
                  </template>
                  </v-data-table>
                  <v-alert
                  border="left"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  <p class="subtitle-1">Keterangan:</p>
                  <div>H: Hadir</div>
                  <div>Xm : Tidak Absen / Check-IN Pagi</div>
                  <div>Xp : Tidak Absen / Check-OUT Sore</div>
                  <div>X  : Tidak Absen</div>
                </v-alert>
                </base-material-card>
            </v-tab-item>

            <v-tab-item>
              <base-material-card
                  icon="mdi-clipboard-text"
                  title="List Data Absen"
                  class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                        <v-dialog
                          ref="SelMonth"
                          v-model="menuSelMonth"
                          :return-value.sync="selectedMonth"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="selectedMonth"
                            label="Pilih Bulan/Tahun"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedMonth" type="month" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuSelMonth = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.SelMonth.save(selectedMonth)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>

                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="feedingReportTable()">Show Data</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" v-on="on">
                            <export-excel
                                :disabled="listAbsensAllFileDW.length <= 0"
                                :data="listAbsensAllFileDW"
                                :name="excelfName"
                                type="xls"
                                :title="excelTitle"
                                >
                                Download Data
                                <v-icon color="green darken-4">mdi-download</v-icon>
                            </export-excel>
                          </v-btn>
                        </template>
                        <span v-if="listAbsensAllFileDW.length > 0">Silakan Download Excel KLIK Disini.</span>
                        <span v-else>Silakan Refresh Data Terlebih dahulu.</span>
                      </v-tooltip>
                      
                    </v-col>
                  </v-row>
                  

                  <v-data-table
                    :headers="header_report"
                    :items="listAbsensAll"
                    :items-per-page="15"
                    class="elevation-1"
                  >
                
                  </v-data-table>
                  <v-alert
                  border="left"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  <p class="subtitle-1">Keterangan:</p>
                  <div>H: Hadir</div>
                  <div>Xm : Tidak Absen / Check-IN Pagi</div>
                  <div>Xp : Tidak Absen / Check-OUT Sore</div>
                  <div>X  : Tidak Absen</div>
                </v-alert>

                </base-material-card>
            </v-tab-item>

            <v-tab-item>
              <base-material-card
                  icon="mdi-clipboard-text"
                  title="List Data Absen dengan Waktu"
                  class="px-5 py-3"
                >
                  <v-row>
                    <v-col>
                        <v-dialog
                          ref="SelMonth"
                          v-model="menuSelMonth"
                          :return-value.sync="selectedMonth"
                          persistent
                          width="290px"
                        >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="selectedMonth"
                            label="Pilih Bulan/Tahun"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="selectedMonth" type="month" scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="menuSelMonth = false">Cancel</v-btn>
                          <v-btn text color="primary" @click="$refs.SelMonth.save(selectedMonth)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>

                    </v-col>
                    <v-col>
                    <v-btn color="blue" @click="feedingReportWaktuTable()">Show Data</v-btn>
                    </v-col>
                    <v-col class="text-right">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn color="primary" v-on="on">
                            <export-excel
                                :disabled="listAbsensAllFileDW2.length <= 0"
                                :data="listAbsensAllFileDW2"
                                :name="excelfName2"
                                type="xls"
                                :title="excelTitle"
                                >
                                Download Data
                                <v-icon color="green darken-4">mdi-download</v-icon>
                            </export-excel>
                          </v-btn>
                        </template>
                        <span v-if="listAbsensAllFileDW2.length > 0">Silakan Download Excel KLIK Disini.</span>
                        <span v-else>Silakan Refresh Data Terlebih dahulu.</span>
                      </v-tooltip>
                      
                    </v-col>
                  </v-row>
                  

                  <v-data-table
                    :headers="header_report"
                    :items="listAbsensAll2"
                    :items-per-page="15"
                    class="elevation-1"
                  >
                
                  </v-data-table>
                  <v-alert
                  border="left"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  <p class="subtitle-1">Keterangan:</p>
                  
                </v-alert>

                </base-material-card>
            </v-tab-item>

          </v-tabs-items>

        </base-material-tabs>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

    <v-dialog v-model="dialogIN" persistent max-width="70vW">
      <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="headline">Absen Check-IN WFO/WFH?</v-card-title>
          <v-card-text class="px-5 py-5">
            <v-row>
            
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                :items="listStatus"
                name="value"
                label="Status Kerja"
                v-model="extra.worksts"
                item-text="text"
                @change="checkStatus()"
                :rules="[rules.required]"
                ></v-select>
          
              </v-col>

              <v-col
                cols="12"
                md="6"
                v-if="extra.worksts == 'WFH'"
              >
              <v-text-field
                v-if="extra.worksts == 'WFH'"
                v-model="extra.worksts_ST"
                label="Surat Tugas WFH"
                
                :rules="[rules.required]"
              ></v-text-field>  
            </v-col>
            <v-col
                cols="12"
                md="6"
                v-else-if="extra.worksts == 'WFO'"
              ><!--
              <v-alert
              dense
              outlined
              type="error"
              >Absen WFO hanya dapat dilakukan pada <b>Jaringan Internet Kantor (WIFI / LAN(PC) ) BPDPKS</b>, untuk yang menggunakan WIFI silakan konek terlebih dahulu ke <b>WIFI 'BPDP-KS atau BPDP-K5'</b>.</v-alert>-->

            </v-col>
            <v-col
                cols="12"
                md="6"
                v-else
              >
              <v-alert
              dense
              outlined
              type="warning"
              >Silakan pilih status Kerja anda saat ini dalam kondisi <b>WFO</b> atau <b>WFH</b>, Jika WFH pastikan <b>Surat Tugas</b> sudah diberikan kepada <b>Divisi Umum dan SDM</b>.</v-alert>
            </v-col>
            <v-col
                cols="12"
                md="12"
                class="amber lighten-4"
              >
            Silakan isi Form diatas dan klik Submit, untuk melanjutkan.
            </v-col>

            </v-row>
            
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="dialogIN = false">Batal</v-btn>
          <v-btn color="blue darken-1" :disabled="!valid" @click="checkIN()">Submit</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="dialogOUT" persistent max-width="70vW">
      <v-form ref="form" v-model="valid">
      <v-card>
        <v-card-title class="headline">Absen Check-OUT WFO/WFH?</v-card-title>
          <v-card-text>
            <br>
            <v-col
                cols="12"
                md="12"
                class="amber lighten-4"
              >
            Silakan klik Submit, untuk melanjutkan.
            </v-col>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning darken-1" text @click="dialogOUT = false">Batal</v-btn>
          <v-btn color="blue darken-1" :disabled="!valid" @click="checkOUT()">Submit</v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  moment.locale();
  import axios from 'axios'

  import { getSettingParam } from '@/api/settingparam'
  import { getAbsen, getListByMonthAllPegawai, insertAbsenOnline, updateAbsenOnline, getSvrtime, getHolidayByDate } from '@/api/absenonline'

  export default {
    name: 'DashboardPanels',

    data: () => ({
      gMapAPI: 'AIzaSyC4PUILeHz6rGOWsr5crcumVuiu4xzmzfE', 
      isAdmin: false,
      tab: 0,
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      tabs: [
        {
          text: 'Absensi',
          icon: 'mdi-information',
        },
        {
          text: 'Data Absen',
          icon: 'mdi-information',
        }
      ],
      absenIn: '-',
      locIn: '-',
      infoTL: '-',
      absenOut: '-',
      locOut: '-',
      infoPSW: '-',
      boolBtnIN: false,
      boolBtnOUT: false,
      dialogIN: false,
      dialogOUT: false,
      coordinates: '',
      canAbsenOnline: 1,
      dataAbsen: null,
      dataUser: null,
      isLoading: false,
      menuSelMonth: false,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: true,
          value: 'id',
        },
        { text: 'NIK', value: 'UserNIK', align: 'center', class:'subtitle-1' },
        { text: 'Tanggal', value: 'tgl', align: 'center', sortable: true, class:'subtitle-1' },
        { text: 'Check-IN', value: 'hdateIN', align: 'center', class:'subtitle-1' },
        { text: 'Check-OUT', value: 'hdateOUT', align: 'center', class:'subtitle-1' },
        { text: 'Status', value: 'stswfh', align: 'center', class:'subtitle-1'},
        { text: 'Kehadiran', value: 'sts', align: 'center', class:'subtitle-1'},
        /*{ text: 'Ket', value: 'ket', align: 'start', class:'subtitle-1'}*/
      ],
      header_report:[],
      listAbsens: [],
      listAbsensAll: [],
      listAbsensAllFileDW: [],
      listAbsensAll2: [],
      listAbsensAllFileDW2: [],
      listofDate: [],
      selectedMonth: new Date().toISOString().substr(0, 7),
      excelfName: 'AbsenWFH_' + moment().format('YYYYMM') + '_' + moment().format() + '.xls',
      excelfName2: 'JamAbsenWFH_' + moment().format('YYYYMM') + '_' + moment().format() + '.xls',
      excelTitle: '',
      diffMinute: 0,
      address: null,
      geoip:null,
      listStatus: [
        { text: 'WFH', value: 'WFH'},
        { text: 'WFO', value: 'WFO'}
      ],
      extra: {
        worksts: '',
        worksts_ST: ''
      },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
      },
      servertime: null,
      vrefresh: null,
      vrefreshAbsen: null,
      holidayInfo: '',
      todayisHoliday: false

    }),
    filters: {
      formatDate: function (value) {
        if (!value || value === '-') return 'NA'
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
      computedValue: function(){
          return this.servertime;
      }
    },
    created () {
      const vm = this
      let sUser = localStorage.getItem('user')
      vm.dataUser = JSON.parse(sUser)
      vm.isAdmin = false
      if (vm.dataUser !== null) {
        // console.log(vm.dataUser)
        if(vm.dataUser.role !== null) {
          if(vm.dataUser.role.id === 1) {
            vm.isAdmin = true
          }
        }
      }

      vm.checkSetting()
      vm.coordinates = null

      if (vm.isAdmin) {
        vm.tabs = [
          {
            text: 'Absensi',
            icon: 'mdi-information',
          },
          {
          text: 'Data Absen',
          icon: 'mdi-information',
          },
          {
            text: 'Rekap Absen Seluruh Pegawai',
            icon: 'mdi-information',
          },
          {
            text: 'Rekap JAM Absen Seluruh Pegawai',
            icon: 'mdi-clock',
          }
        ]
        vm.prepareReports()
      }
      
      navigator.geolocation.getCurrentPosition(
        function () { console.log('loc success') },
        function (error) {
          console.log('error')
          vm.toast = {
              show: true, color:'red', text: vm.locationError(error), timeout: 2000
          }
        }
      )

      vm.$getLocation({
        enableHighAccuracy: true, // defaults to false
        timeout: Infinity, // defaults to Infinity
        maximumAge: 0 // defaults to 0
      })
      .then(coordinates => {
        vm.coordinates = coordinates
        console.log('coordinates:', coordinates)

        // vm.getStreetAddressFrom(coordinates.lat, coordinates.lng) //Enable location dulu
      })

      vm.vrefresh = setInterval(function(){
        vm.timeChecker()
      }, 5000)

      vm.vrefreshAbsen = setInterval(function(){
        vm.getdataAbsen()
      }, 1000 * 60 * 30) // 1 Hour

    },
    destroyed () {
      clearInterval(this.vrefresh)
      clearInterval(this.vrefreshAbsen)
    },

    mounted () {
      const vm = this

      vm.getGeoIP()


      getSvrtime().then(response => {
        console.log('init server time', response.data)
        var svrtime = response.data

        vm.servertime = moment(svrtime)
        var today = moment(vm.servertime).format('YYYY-MM-DD')

        vm.holidayInfo = ''
        vm.todayisHoliday = false

        vm.getdataAbsen()
        // vm.feedingTable()

        getHolidayByDate(today).then(response => {
          // console.log('getHolidayByDate', response.data)
          var dholidays = response.data

          if(dholidays){
            var head = '<ul>'
            var child = ''

            function removeDuplicates(data) {
                var arr = [];
                return data.filter(function(x) {
                    return !contains(arr, x) && arr.push(x);
                });
            }
            function contains(array, obj) {
                for (var i = 0; i < array.length; i++) {
                    if (isEqual(array[i], obj)) return true;
                }
                return false;
            }
            //comparator
            function isEqual(obj1, obj2) {
                if (obj1.name == obj2.name) return true;
                return false;
            }

            var filtered = removeDuplicates(dholidays)
            // console.log('holidayInfoAll', filtered)
            
            filtered.forEach(el => {
              if(el.type[0] == 'National holiday'){
                // console.log('holidayInfo', el)
                var strdate = moment(el.date['iso'],'YYYY-MM-DD').format('LL')
                child = child + '<li>'
                if(el.date['iso'] === today){
                  vm.todayisHoliday = true
                  var strname = el.name
                  child = child + '<span class="display-2">' + strdate + ': ' + strname + '</span>'
                }else{
                  child = child + strdate + ': ' + el.name 
                }
                
                child = child + '<br><span class="caption font-italic font-weight-light">' + el.description + '</span>'
                child = child + '</li>'
              }
            });
            vm.holidayInfo = head + child + '</ul>'
            // console.log('holidayInfo', vm.holidayInfo)
          }
          
        }).catch(err => {
          console.log(err)
        })
        
      }).catch(err => {
        vm.servertime = null
        console.log(err)
      })
      
    },
    methods: {
      locationError (error) {
        this.coordinates = null
        switch (error.code) {
          case error.PERMISSION_DENIED:
            return 'User denied the request for Geolocation.'
          case error.POSITION_UNAVAILABLE:
            return 'Location information is unavailable.'
          case error.TIMEOUT:
            return 'The request to get user location timed out.'
          case error.UNKNOWN_ERROR:
            return 'An unknown error occurred.'
        }
      },
      checkSetting () {
        const vm = this
        vm.isLoading = true
        vm.canAbsenOnline = 1
        getSettingParam().then(response => {
          // console.log('SettingParam', response.data)
          var array = response.data
          vm.canAbsenOnline = 0 // here

          if (array !== null) {
            if (array.length > 0) {
              array.forEach(element => {
                // console.log('element', element)
                if (element.Deskripsi === 'isAbsenOnline') {
                  vm.canAbsenOnline = element.value
                }
              })
            }
          }
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.canAbsenOnline = 1
          vm.isLoading = false
        })
      },
      checkStatus(){
        console.log('checkStatus', this.extra.worksts)
      },
      feedingTable () {
        const vm = this
        var user = vm.dataUser
        var month = moment(vm.selectedMonth).format('YYYY-MM')
        vm.isLoading = true
        getAbsen(user.nik, month).then(response => {
          // console.log('getListByMonth', response.data)
          var data = response.data
          var result = []
          if (data.length > 0) {
            
            data.forEach(element => {
              // console.log(element)
              element['sts'] = 'H'
              if (moment(element.dateIN).isValid()) {
                element['tgl'] = moment(element.dateIN).format('DD MMM YYYY')
                element['hdateIN'] = moment(element.dateIN).format('HH:mm:ss')
              } 
              else {
                element['hdateIN'] = '-'
                element['sts'] = 'Xm'
                // console.log('hdateIN else')
              }

              element['stswfh'] = '-'
              if (element.workstatus) {
                if(element.workstatus_ST){
                  element['stswfh'] = element.workstatus + ' (' + element.workstatus_ST + ')'
                }else{
                  element['stswfh'] = element.workstatus
                }
              } 

              if (moment(element.dateOUT).isValid()) {
                element['tgl'] = moment(element.dateOUT).format('DD MMM YYYY')
                element['hdateOUT'] = moment(element.dateOUT).format('HH:mm:ss')
              } 
              else {
                element['hdateOUT'] = '-'

                if(!moment().isSame(element.dateIN, 'day')){
                  element['sts'] = 'Xp'
                }
                else {
                  element['sts'] = '-'
                }
                
                // console.log('hdateOUT else')
              }
              var isXmdouble = false
              if( element['sts'] === 'Xm'){
                // console.log(element['sts'],element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateIN).isValid()) {
                      element['tgl'] = moment(el.dateIN).format('DD MMM YYYY')
                      element['hdateIN'] = moment(el.dateIN).format('HH:mm:ss')
                      element['sts'] = 'Xp'
                      // console.log(element)
                      isXpdouble = true
                    }
                  })
                }
              } 
              var isXpdouble = false
              if( element['sts'] === 'Xp'){
                // console.log(element['sts'], element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateOUT).isValid()) {
                      element['tgl'] = moment(el.dateOUT).format('DD MMM YYYY')
                      element['hdateOUT'] = moment(el.dateOUT).format('HH:mm:ss')
                      element['sts'] = 'H'
                      // console.log(element)
                      isXpdouble = true
                    }
                  })
                }
              }
              

              if (!moment(element.dateIN).isValid() && !moment(element.dateOUT).isValid()) {
                element['tgl'] = '-'
                element['hdateIN'] = '-'
                element['hdateOUT'] = '-'
                element['sts'] = 'X'
                // console.log('hdateIN hdateOUT else')
              }

              if(isXmdouble || isXpdouble){
                console.log('double data: ',element['tgl'])
              }else{
                result.push(element)
              }

              if(element['INLat'] && element['INLong']){
                if(element['INLat'] != '' && element['INLong'] != ''){
                  // console.log(element['INLat'])
                }
              }
              
            })
          }

          
          let sorted = result.sort((a, b) => {
              const adate = moment(a.tgl, 'DD MMM YYYY')
              const bdate = moment(b.tgl, 'DD MMM YYYY')
              if (!a || !b || !a.tgl || !b.tgl) return 0;
              else return bdate - adate;
          });
          

          vm.listAbsens = sorted
          vm.isLoading = false
        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
      getGeoIP(){
        const vm = this
        axios.get('https://api.ipify.org?format=json')
        .then(response => {
            if(!response) 
              throw Error('No geoIP found.')

            if(response.data){
              vm.geoip = response.data.ip
              console.log('geoip', vm.geoip)
            }
            
        }).catch(error => {
            console.log(error);
            throw error;
        })
      },
      getStreetAddressFrom(slat, slong) {
        const vm = this
        /*
        var mapquestKEY = 'NVqlWIpdIc5nfVHXz9Hpdk0jyoSavO9C'
        var openCageKEY = '4266aeb426ee49a2bd745430981b2662'
        var googleKEY = 'AIzaSyAAegsX2XU7VyqPWF3e4KXoUapSYbJSUv0'
        var yandexKEY = '4da4ff71-575c-462e-9358-34f567aa7df9'

        var uri = "http://www.mapquestapi.com/geocoding/v1/reverse?key=" + mapquestKEY + "&location=" + slat+ "," + slong + "&includeRoadMetadata=true&includeNearestIntersection=true"
        uri = "https://api.opencagedata.com/geocode/v1/json?key=" + openCageKEY + "&q=" + slat+ "+" + slong
        uri = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +slat+ "," +slong+ "&key=" + googleKEY
        uri = "https://geocode-maps.yandex.ru/1.x/?apikey="+ yandexKEY+"&geocode="  +slat+ "," +slong + "&lang=en-US"

        console.log('uri', uri)
        return axios.get(uri, {json: true})
        .then(response => {
            if(!response.data) 
              throw Error('No location found.')

            vm.address = response.data;
            console.log('address:', vm.address)
        }).catch(error => {
            console.log(error);
            throw error;
        })
        lat: "-6.2660152"
limit: "1"
lon: "106.9275053"
        */
      
       var qlat = slat.toString()
       var qlong = slong.toString()

        var options = {
          method: 'GET',
          url: 'https://geocode-address-to-location.p.rapidapi.com/v1/geocode/search',
          params: {
            text: 'Posisi',
            lon: qlong,
            lat: qlat,
            limit: '1',
            lang: 'id'
          },
          headers: {
            'x-rapidapi-host': 'geocode-address-to-location.p.rapidapi.com',
            'x-rapidapi-key': 'DaZNXbFpi7mshCgNEJIbR3klKzdHp15KIgHjsnGFwjBmOk2uUx'
          }
        };


        console.log('getStreetAddressFrom params:', options)
        axios.request(options).then(function (response) {
          vm.address = response.data;
            console.log('address:', vm.address)
        }).catch(function (error) {
          console.error(error);
        });

      },
      getdataAbsen () {
        const vm = this
        var user = vm.dataUser
        var today = moment(vm.servertime).format('YYYY-MM-DD')

        getAbsen(user.nik, today, vm.extra.worksts).then(response => {
          // console.log('getAbsenToday', response.data)
          var data = response.data
          if (data.length > 0) {
            vm.dataAbsen = data[0]

            vm.extra.worksts = vm.dataAbsen.workstatus
            if(!vm.extra.worksts){
              vm.extra.worksts = 'WFH'
            }
            vm.extra.worksts_ST = vm.dataAbsen.workstatus_ST
          }
          this.refresh()
          console.log('vm.extra', vm.extra)
 
        }).catch(err => {
          console.log(err)
        })
      },
      timeChecker () {

         // TESTING
        /* 
        this.boolBtnIN = true
        this.boolBtnOUT = true
        this.diffMinute = 570
        return true
        */

        // DISABLED: adopsi lembur sabtu minggu dibuka untuk absen sipeg
        /*
        if (moment().day() === 0 || moment().day() === 6 ||this.todayisHoliday) {
          this.boolBtnIN = false
          this.boolBtnOUT = false
          // console.log('today is not office day..')
          return true
        }
        */
        
        var svrtime = this.servertime
        // var sample = '2021-04-13 15:30:00'
        // svrtime = moment(new Date(sample))
        console.log('svrtime: ', svrtime)

        const now = moment()
        // console.log('daysame: ', svrtime.isSame(now, 'day'))
        if (!svrtime.isSame(now, 'day')) {
          console.log('today not same with server day!!')
          return false
        }

        const ndate = moment(svrtime).format('YYYY-MM-DD')
        var inbeforeTime = moment(ndate + ' 05:00:00').format('YYYY-MM-DD HH:mm:ss') //05
        var inafterTime = moment(ndate + ' 09:00:00').format('YYYY-MM-DD HH:mm:ss') //09
        var outbeforeTime = moment(ndate + ' 15:30:00').format('YYYY-MM-DD HH:mm:ss') //15
        var outafterTime = moment(ndate + ' 23:59:59').format('YYYY-MM-DD HH:mm:ss') //23

        //Ramadhan
        /*
        inbeforeTime = moment(ndate + ' 06:00:00').format('YYYY-MM-DD HH:mm:ss') //06
        inafterTime = moment(ndate + ' 09:00:00').format('YYYY-MM-DD HH:mm:ss')
        outbeforeTime = moment(ndate + ' 14:00:00').format('YYYY-MM-DD HH:mm:ss')
        */
        //---

        var absenIn = this.absenIn
        // var sample = '2020-05-23 05:00:00'
        // var absenIn = moment(new Date(sample)).format('YYYY-MM-DD HH:mm:ss')
        console.log("--1 absenIn",absenIn)
        if (moment(new Date(absenIn)).isValid()) {
          this.boolBtnIN = false
        }
        else {
          console.log("--1 inbeforeTime",inbeforeTime)
          console.log("--1 inafterTime",inafterTime)
          if (svrtime.isBetween(inbeforeTime, inafterTime)) {
            this.boolBtnIN = true
          }
          else {
            this.boolBtnIN = false
          }
        }
        var absenOut = this.absenOut
        console.log("--2 absenOut",absenOut)
        if (moment(new Date(absenOut)).isValid()) {
          this.boolBtnOUT = false
          this.boolBtnIN = false
        }
        else {
          console.log("--2 outbeforeTime",outbeforeTime)
          console.log("--2 outafterTime",outafterTime)
          if (svrtime.isBetween(outbeforeTime, outafterTime)) {
            if (moment(new Date(absenIn)).isValid()) {
              var a = moment(new Date(absenIn), "HH:mm:ss")
              var b = moment(svrtime, "HH:mm:ss")
              this.diffMinute = b.diff(a, 'minutes')
              console.log('diff1:', this.diffMinute)
            }else{
              var vmabsenin = moment().format('YYYY-MM-DD 08:00:00')
              var a = moment(new Date(vmabsenin), "HH:mm:ss")
              var b = moment(svrtime, "HH:mm:ss")
              this.diffMinute = b.diff(a, 'minutes')
              console.log('diff2:', this.diffMinute)
            }
            this.boolBtnOUT = true
          }else {
            this.boolBtnOUT = false
          }
        }

        console.log('params ', svrtime._d, this.boolBtnIN, absenIn, this.boolBtnOUT, absenOut)
      },
      openMap (long, lat) {
        console.log('long: ', long)
        console.log('lat: ', lat)
      },
      refresh () {
        var dabsen = this.dataAbsen
        if (dabsen !== null) {
          if (dabsen.dateIN !== null) {
            this.absenIn = moment(dabsen.dateIN,'YYYY-MM-DD HH:mm:ss').add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
            this.absenIn = moment(this.absenIn).toDate().getTime()
            console.log('inid:', dabsen.id)
            this.locIn = '' + dabsen.INLong + ' | ' + dabsen.INLat
          }
          if (dabsen.dateOUT !== null) {
            this.absenOut = moment(dabsen.dateOUT,'YYYY-MM-DD HH:mm:ss').add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
            this.absenOut = moment(this.absenOut).toDate().getTime()
            console.log('outid:', dabsen.id)
            this.locOut = '' + dabsen.OUTLong + ' | ' + dabsen.OUTLat
          }
        }
        this.timeChecker()
      },
      checkIN () {
        console.log('checkin')
        const vm = this

        vm.dialogIN = false
        var usr = vm.dataUser
        var now = moment().add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
        var long = ''
        var lat = ''
        if (vm.coordinates !== null) {
          long = vm.coordinates.lng
          lat = vm.coordinates.lat
        }


        var today = moment(vm.servertime).format('YYYY-MM-DD')

        getAbsen(usr.nik, today, vm.extra.worksts).then(response => {
          // console.log('getAbsenToday', response.data)
          var data = response.data
          if (data.length > 0) {
            vm.dataAbsen = data[0]

            vm.extra.worksts = vm.dataAbsen.workstatus
            if(!vm.extra.worksts){
              vm.extra.worksts = 'WFH'
            }
            vm.extra.worksts_ST = vm.dataAbsen.workstatus_ST

            if (vm.dataAbsen !== null) {
              if(vm.dataAbsen.dateIN == null){
                var updData = {
                  user: usr.id,
                  UserNIK: usr.nik,
                  dateIN: now,
                  OUTLong: long.toString(),
                  OUTLat: lat.toString(),
                  status: 'in',
                  workstatus: vm.extra.worksts,
                  workstatus_ST: vm.extra.worksts_ST
                }
                var idb = vm.dataAbsen.id
                // console.log('id absenonline: ', idb)
                vm.isLoading = true
                updateAbsenOnline('in', updData, idb, vm.extra.worksts).then(response => {
                  // console.log('updateAbsenOnline in', response.data)
                  var data = response.data
                  vm.dataAbsen = data
                  vm.isLoading = false
                  vm.refresh()
                  vm.toast = {
                    show: true, color:'primary', text: 'Absen Check-IN telah BERHASIL.', timeout: 2000
                  }
                }).catch(err => {
                  console.log(err)
                  vm.isLoading = false
                  vm.toast = {
                    show: true, color:'red', text: 'Absen Check-IN GAGAL, silakan lakukan Refresh Browser atau Logout dan Login ulang kembali.', timeout: 2000
                  }
                })
              }else{
                vm.refresh()
                vm.toast = {
                  show: true, color:'primary', text: 'Anda telah Absen Check-IN.', timeout: 2000
                }
              }
            }
          }else {
            var insData = {
              user: usr.id,
              UserNIK: usr.nik,
              dateIN: now,
              INLong: long.toString(),
              INLat: lat.toString(),
              status: 'in',
              workstatus: vm.extra.worksts,
              workstatus_ST: vm.extra.worksts_ST
            }
            vm.isLoading = true
            insertAbsenOnline('in', insData, vm.extra.worksts).then(response => {
              // console.log('insertAbsenOnline in', response.data)
              var data = response.data
              vm.dataAbsen = data
              vm.isLoading = false
              vm.refresh()
              vm.toast = {
                show: true, color:'green', text: 'Absen Check-IN telah BERHASIL.', timeout: 2000
              }
            }).catch(err => {
              console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Absen Check-IN GAGAL, silakan lakukan Refresh Browser atau Logout dan Login ulang kembali.', timeout: 2000
              }
            })
          }
 
        }).catch(err => {
          console.log(err)
        })
        
        
      },
      checkOUT () {
        console.log('checkout')
        const vm = this

        /* // TESTING
        console.log('vm.extra.worksts',vm.extra.worksts)
        return true
        */

        vm.dialogOUT = false
        var usr = vm.dataUser
        var now = moment().add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
        var long = ''
        var lat = ''
        if (vm.coordinates !== null) {
          long = vm.coordinates.lng
          lat = vm.coordinates.lat
        }

        var today = moment(vm.servertime).format('YYYY-MM-DD')

        getAbsen(usr.nik, today, vm.extra.worksts).then(response => {
          // console.log('getAbsenToday', response.data)
          var data = response.data
          if (data.length > 0) {
            vm.dataAbsen = data[0]

            vm.extra.worksts = vm.dataAbsen.workstatus
            if(!vm.extra.worksts){
              vm.extra.worksts = 'WFH'
            }
            vm.extra.worksts_ST = vm.dataAbsen.workstatus_ST

            if (vm.dataAbsen !== null) {
              if(vm.dataAbsen.dateOUT == null){
                var updData = {
                  user: usr.id,
                  UserNIK: usr.nik,
                  dateOUT: now,
                  OUTLong: long.toString(),
                  OUTLat: lat.toString(),
                  status: "out",
                  updated_at: now,
                }
                var idb = vm.dataAbsen.id
                // console.log('id absenonline: ', idb)
                vm.isLoading = true
                updateAbsenOnline('out', updData, idb, vm.extra.worksts).then(response => {
                  // console.log('updateAbsenOnline', response.data)
                  var data = response.data
                  vm.dataAbsen = data
                  vm.isLoading = false
                  vm.refresh()
                  vm.toast = {
                    show: true, color:'primary', text: 'Absen Check-OUT telah BERHASIL.', timeout: 2000
                  }
                }).catch(err => {
                  console.log(err)
                  vm.isLoading = false
                  vm.toast = {
                    show: true, color:'red', text: 'Absen Check-OUT GAGAL, silakan lakukan Refresh Browser atau Logout dan Login ulang kembali.', timeout: 2000
                  }
                })
              }else{
                vm.refresh()
                vm.toast = {
                  show: true, color:'primary', text: 'Anda telah Absen Check-OUT.', timeout: 2000
                }
              }
            }
          } else{
            var insData = {
              user: usr.id,
              UserNIK: usr.nik,
              dateOUT: now,
              OUTLong: long.toString(),
              OUTLat: lat.toString(),
              status: "out",
            }
            vm.isLoading = true
            insertAbsenOnline('out', insData, vm.extra.worksts).then(response => {
              // console.log('insertAbsenOnline', response.data)
              var data = response.data
              vm.dataAbsen = data
              vm.isLoading = false
              vm.refresh()
              vm.toast = {
                show: true, color:'green', text: 'Absen Check-OUT telah BERHASIL.', timeout: 2000
              }
            }).catch(err => {
              console.log(err)
              vm.isLoading = false
              vm.toast = {
                show: true, color:'red', text: 'Absen Check-OUT GAGAL, silakan lakukan Refresh Browser atau Logout dan Login ulang kembali.', timeout: 2000
              }
            })
          }
 
        }).catch(err => {
          console.log(err)
        })

        
      },
      prepareReports () {
        const vm = this
        vm.header_report= [
          { text: 'NIK', value: 'UserNIK', align: 'center', sortable: true, class:'subtitle-1' },
          { text: 'Nama', value: 'fullname', align: 'center', sortable: true, class:'subtitle-1' },
        ]

        function getAllDatesOfMonth() {
          var ar = [];
          var start = moment(vm.selectedMonth,"YYYY-MM");
          for(var end = moment(start).add(1,'month');  start.isBefore(end); start.add(1,'day')){
            if (moment(start).day() !== 0 && moment(start).day() !== 6) { //Workdays ONLY
              ar.push(start.format('YYYY-MM-DD'))
            } 
          }
          return ar;
        }
        vm.listofDate = getAllDatesOfMonth()
        // console.log(objData)

        vm.listofDate.forEach(element => {
          var temp = { text: element, value: element, align: 'center', class:'subtitle-1' }
          vm.header_report.push(temp)
        })
        
        // console.log(vm.header_report)
      },
      feedingReportTable () {
        const vm = this
        var user = vm.dataUser
        var month = moment(vm.selectedMonth).format('YYYY-MM')
        var smonth = moment(vm.selectedMonth).format('MMM YYYY')
        vm.excelTitle = 'DATA ABSENSI WFH ' + smonth
        vm.prepareReports ()

        vm.listAbsensAllFileDW = []
        vm.listAbsensAll = []
        vm.isLoading = true
        getListByMonthAllPegawai(month).then(response => {
          // console.log('getListByMonth', response.data)
          var data = response.data
          var tmpData = []
          if (data.length > 0) {
            data.forEach(element => {
              // console.log(element)
              element['sts'] = 'H'
              if (moment(element.dateIN).isValid()) {
                element['tgl'] = moment(element.dateIN).format('YYYY-MM-DD')
              } 
              else {
                element['hdateIN'] = '-'
                element['sts'] = 'Xm'
                // console.log('hdateIN else')
              }

              if (moment(element.dateOUT).isValid()) {
                element['tgl'] = moment(element.dateOUT).format('YYYY-MM-DD')
              } 
              else {
                if(!moment().isSame(element.dateIN, 'day')){
                  element['sts'] = 'Xp'
                }
                else {
                  element['sts'] = '-'
                }
                // console.log('hdateOUT else')
              } 

              if( element['sts'] === 'Xm'){
                // console.log(element['sts'],element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateIN).isValid()) {
                      element['tgl'] = moment(el.dateIN).format('DD MMM YYYY')
                      element['hdateIN'] = moment(el.dateIN).format('HH:mm:ss')
                      element['sts'] = 'Xp'
                      // console.log(element)
                    }
                  })
                }
              } 
              if( element['sts'] === 'Xp'){
                // console.log(element['sts'], element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateOUT).isValid()) {
                      element['tgl'] = moment(el.dateOUT).format('DD MMM YYYY')
                      element['hdateOUT'] = moment(el.dateOUT).format('HH:mm:ss')
                      element['sts'] = 'H'
                      // console.log(element)
                    }
                  })
                }
              }
              
              var strsts = element['sts']
              element['stsdw'] = strsts
              element['stswfh'] = '-'
              if (element.workstatus) {
                if(element.workstatus_ST){
                  element['stswfh'] = element.workstatus + ' (' + element.workstatus_ST + ')'
                }else{
                  element['stswfh'] = element.workstatus
                }
                element['sts'] = strsts + ' | ' + element['stswfh']
              }

              tmpData.push()
              
            })
          }
          
          tmpData = data
          // Parse Unique with NIK 
          const unique = [...new Set(tmpData.map(item => item.UserNIK), tmpData.map(item => item.fullname))];
          var unique2 = []
          // Parse Unique with NIK and Fullname
          if (unique.length > 0){
            unique.forEach(elnik => {
              var cnt = 0
              tmpData.forEach(elData => {
                if (elData.UserNIK === elnik && cnt == 0){
                  var tmp = { 'UserNIK': elData.UserNIK, 'fullname': elData.fullname}
                  unique2.push(tmp)
                  cnt++
                }
                else {
                  return
                }            
              })
            })
          }
          // Parse per Date
          var newData = []
          if (unique2.length > 0){
            unique2.forEach(elnik => {
              var map = new Map()
              map.set('UserNIK', elnik.UserNIK)
              map.set('fullname', elnik.fullname)
              var items = tmpData.filter( i => i.UserNIK === elnik.UserNIK)
              var ldate = vm.listofDate
              ldate.forEach(eltgl => {
                map.set(eltgl, 'X')
                items.forEach(elItem => {
                  if (eltgl === elItem.tgl){
                    map.set(elItem.tgl, elItem.sts)
                  } 
                })
                          
              })
              const obj = Object.fromEntries(map);
              newData.push(obj)
            })
          }
          // console.log(newData)
          vm.listAbsensAll = newData
          vm.isLoading = false

          var newDataDW = []
          if (unique2.length > 0){
            unique2.forEach(elnik => {
              var map = new Map()
              map.set('UserNIK', elnik.UserNIK)
              map.set('fullname', elnik.fullname)
              var items = tmpData.filter( i => i.UserNIK === elnik.UserNIK)
              var ldate = vm.listofDate
              ldate.forEach(eltgl => {
                map.set(eltgl, 'X')
                items.forEach(elItem => {
                  if (eltgl === elItem.tgl){
                    map.set(elItem.tgl, elItem.stsdw)
                  } 
                })
                          
              })
              const obj = Object.fromEntries(map);
              newDataDW.push(obj)
            })
          }
          vm.listAbsensAllFileDW = []
          vm.listAbsensAllFileDW = newDataDW
          // console.log('listAbsensAllFileDW', vm.listAbsensAllFileDW)

        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
      feedingReportWaktuTable () {
        const vm = this
        var user = vm.dataUser
        var month = moment(vm.selectedMonth).format('YYYY-MM')
        var smonth = moment(vm.selectedMonth).format('MMM YYYY')
        vm.excelTitle = 'DATA JAM ABSENSI WFH/WFO ' + smonth
        vm.prepareReports ()

        vm.listAbsensAllFileDW2 = []
        vm.listAbsensAll2 = []
        vm.isLoading = true
        getListByMonthAllPegawai(month).then(response => {
          // console.log('getListByMonth', response.data)
          var data = response.data
          var tmpData = []
          if (data.length > 0) {
            data.forEach(element => {
              // console.log(element)
              element['sts'] = 'H'
              if (moment(element.dateIN).isValid()) {
                element['tgl'] = moment(element.dateIN).format('YYYY-MM-DD')
                element['hdateIN'] = moment(element.dateIN).format('HH:mm:ss')
              } 
              else {
                if(!moment().isSame(element.dateIN, 'day')){
                  element['sts'] = 'Xm'
                }
                else {
                  element['sts'] = '-'
                }
                element['hdateIN'] = element['sts']
                // console.log('hdateIN else')
              }

              if (moment(element.dateOUT).isValid()) {
                element['tgl'] = moment(element.dateOUT).format('YYYY-MM-DD')
                element['hdateOUT'] = moment(element.dateOUT).format('HH:mm:ss')
              } 
              else {
                if(!moment().isSame(element.dateIN, 'day')){
                  element['sts'] = 'Xp'
                }
                else {
                  element['sts'] = '-'
                }
                element['hdateOUT'] = element['sts']
                // console.log('hdateOUT else')
              } 

              if( element['sts'] === 'Xm'){
                // console.log(element['sts'],element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateIN).isValid()) {
                      element['tgl'] = moment(el.dateIN).format('DD MMM YYYY')
                      element['hdateIN'] = moment(el.dateIN).format('HH:mm:ss')
                      element['sts'] = 'Xp'
                      // console.log(element)
                    }
                  })
                }
              } 
              if( element['sts'] === 'Xp'){
                // console.log(element['sts'], element['tgl'] )
                var obj = data.filter(x => x.tgl === element['tgl'] && x.fullname === element['fullname'])
                if(obj){
                  obj.forEach(el => {
                    if (moment(el.dateOUT).isValid()) {
                      element['tgl'] = moment(el.dateOUT).format('DD MMM YYYY')
                      element['hdateOUT'] = moment(el.dateOUT).format('HH:mm:ss')
                      element['sts'] = 'H'
                      // console.log(element)
                    }
                  })
                }
              }
            
              element['sts'] = element['hdateIN'] + ' | ' + element['hdateOUT']

              tmpData.push()
              
            })
          }
          
          tmpData = data
          // Parse Unique with NIK 
          const unique = [...new Set(tmpData.map(item => item.UserNIK), tmpData.map(item => item.fullname))];
          var unique2 = []
          // Parse Unique with NIK and Fullname
          if (unique.length > 0){
            unique.forEach(elnik => {
              var cnt = 0
              tmpData.forEach(elData => {
                if (elData.UserNIK === elnik && cnt == 0){
                  var tmp = { 'UserNIK': elData.UserNIK, 'fullname': elData.fullname}
                  unique2.push(tmp)
                  cnt++
                }
                else {
                  return
                }            
              })
            })
          }
          // Parse per Date
          var newData = []
          if (unique2.length > 0){
            unique2.forEach(elnik => {
              var map = new Map()
              map.set('UserNIK', elnik.UserNIK)
              map.set('fullname', elnik.fullname)
              var items = tmpData.filter( i => i.UserNIK === elnik.UserNIK)
              var ldate = vm.listofDate
              ldate.forEach(eltgl => {
                map.set(eltgl, 'X')
                items.forEach(elItem => {
                  if (eltgl === elItem.tgl){
                    map.set(elItem.tgl, elItem.sts)
                  } 
                })
                          
              })
              const obj = Object.fromEntries(map);
              newData.push(obj)
            })
          }
          // console.log(newData)
          vm.listAbsensAll2 = newData
          vm.isLoading = false

          var newDataDW = []
          if (unique2.length > 0){
            unique2.forEach(elnik => {
              var map = new Map()
              map.set('UserNIK', elnik.UserNIK)
              map.set('fullname', elnik.fullname)
              var items = tmpData.filter( i => i.UserNIK === elnik.UserNIK)
              var ldate = vm.listofDate
              ldate.forEach(eltgl => {
                map.set(eltgl, 'X')
                items.forEach(elItem => {
                  if (eltgl === elItem.tgl){
                    map.set(elItem.tgl, elItem.sts)
                  } 
                })
                          
              })
              const obj = Object.fromEntries(map);
              newDataDW.push(obj)
            })
          }
          
          vm.listAbsensAllFileDW2 = newDataDW
          // console.log('listAbsensAllFileDW', vm.listAbsensAllFileDW)

        }).catch(err => {
          console.log(err)
          vm.isLoading = false
        })
      },
    }
  }
</script>
