import request from '../utils/request'
import { _GLOBAL } from '../api/server'

export function getSvrtime () {
  var uri = _GLOBAL.URLBACKEND + '/absenonlines/t/now'
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}

export function getHolidayByDate (sdate) {
  var uri = _GLOBAL.URLBACKEND + '/calendarifics/get/month?sdate=' + sdate
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8'
    },
    data
  })
  // console.log(response)
  return response
}



export function getListByMonthAllPegawai (dateIN) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/f/bymonth?dateQ=' + dateIN
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getAbsen (UserNIK, dateIN, mode) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/f/bynikmonth?UserNIK=' + UserNIK + '&dateQ=' + dateIN + '&_sort=id%3ADESC'
  // console.log(uri)

  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getGmapAddress (lat, long, apikey) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+lat+','+long+'&key='+apikey
  // console.log(uri)
  var data = ''
  // console.log(uri)
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    data
  })
  // console.log(response)
  return response
}

export function insertAbsenOnline (type, data, mode) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/own/createv2'
  if(mode == 'WFO'){
    if(type == 'in' || type == 'out'){
      uri = _GLOBAL.URLBACKEND + '/absenonlines/own/createv2'
      //uri = _GLOBAL.URLBACKEND_ABSENSI_WIFI_LOCAL + '/absenonlines/own/createv2'
    }
  }else{
    uri = _GLOBAL.URLBACKEND + '/absenonlines/own/createv2'
  }
  
  try {
    var response = request({
      url: uri,
      method: 'post',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      },
      data
    })

  } catch (error) {
    console.log(error)
  }
  
  console.log(response)
  return response
}

export function updateAbsenOnline (type, data, id, mode) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  if (!data) return null
  // console.log(data)

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/' + id

  if(mode == 'WFO'){
    if(type == 'in' || type == 'out'){
      uri = _GLOBAL.URLBACKEND + '/absenonlines/' + id
      // uri = _GLOBAL.URLBACKEND_ABSENSI_WIFI_LOCAL + '/absenonlines/' + id
    }
  }else{
    uri = _GLOBAL.URLBACKEND + '/absenonlines/' + id
  }

  try {
    var response = request({
      url: uri,
      method: 'put',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${token}`
      },
      data
    })
  } catch (error) {
    console.log(error)
  }
  
  console.log(response)
  return response
}

export function deleteAbsenOnline (id) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/' + id
  var response = request({
    url: uri,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function countAbsenOnline () {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null
  var data = ''

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/count'
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}

export function getdataforReport (dateQ) {
  var token = localStorage.getItem('jwt')
  if (token.length === 0) return null

  var uri = _GLOBAL.URLBACKEND + '/absenonlines/f/bymonth?dateQ=' + dateQ
  // console.log(uri)
  var data = ''
  var response = request({
    url: uri,
    method: 'get',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${token}`
    },
    data
  })
  // console.log(response)
  return response
}
